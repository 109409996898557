@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/Nunito/Nunito-VariableFont_wght.ttf');
  }

  @font-face {
    font-family: 'KAPastaAldente';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/KAPastaAldente/KAPastaAldente.otf');
  }
  
  .logo {
    font-family: 'KAPastaAldente', 'Brush Script MT', sans-serif;
    font-size: 96px;
    text-align: center;
  }
  
  .logo-nav {
    font-family: 'KAPastaAldente', 'Brush Script MT', sans-serif;
    font-size: 48px;
    text-align: center;
  }

  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }

  input.hide-buttons::-webkit-outer-spin-button,
  input.hide-buttons::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input.hide-buttons[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }

  select.hide-buttons {
    background-image: none;
  }

  select.text-left {
    text-align-last: left;
  }

  select.text-center {
    text-align-last: center;
  }

  select.text-right {
    text-align-last: right;
  }

  select.text-justify {
    text-align-last: justify;
  }

}